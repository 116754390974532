<template>
  <v-row class="mt-15">
    <v-col cols="6" offset="3" >
      <v-alert variant="outlined" border="start" color="error" class="text-center"> <H1>403</H1> <br> ACESSO NEGADO <br> &nbsp;</v-alert>
    </v-col>
  </v-row>
</template>
<script setup>
definePage({
  meta: {
    title: "Acesso Negado",
  },
});
</script>
