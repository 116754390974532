<template>
  <v-container>
    <v-row>
      <v-col>
        <v-responsive class="align-centerfill-height mx-auto" max-width="900">
          <v-img class="mb-4" height="217" src="@/assets/malogo.png" />
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
definePage({
  meta: {
    title: "Página Inicial",
  },
});
</script>
