import _definePage_default_0 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/index.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/[...path].vue?definePage&vue&lang.tsx'
import _definePage_default_3 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Cadastro/Empresa/index.vue?definePage&vue&lang.tsx'
import _definePage_default_4 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Cadastro/Empresa/Edit/[id].vue?definePage&vue&lang.tsx'
import _definePage_default_5 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Login.vue?definePage&vue&lang.tsx'
import _definePage_default_6 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Logout.vue?definePage&vue&lang.tsx'
import _definePage_default_7 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Negado.vue?definePage&vue&lang.tsx'
import _definePage_default_8 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Arquivo.vue?definePage&vue&lang.tsx'
import _definePage_default_9 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Grupo/index.vue?definePage&vue&lang.tsx'
import _definePage_default_10 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Grupo/Edit/[id].vue?definePage&vue&lang.tsx'
import _definePage_default_11 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Menu.vue?definePage&vue&lang.tsx'
import _definePage_default_12 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Parametro.vue?definePage&vue&lang.tsx'
import _definePage_default_13 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Permissao/index.vue?definePage&vue&lang.tsx'
import _definePage_default_14 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Permissao/Edit/[id].vue?definePage&vue&lang.tsx'
import _definePage_default_15 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Usuario/index.vue?definePage&vue&lang.tsx'
import _definePage_default_16 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Usuario/Edit/[id].vue?definePage&vue&lang.tsx'
import _definePage_default_17 from '/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/TrocarSenha.vue?definePage&vue&lang.tsx'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: '/',
    component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:path(.*)',
    name: '/[...path]',
    component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/[...path].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/Cadastro',
    /* internal name: '/Cadastro' */
    /* no component */
    children: [
      {
        path: 'Empresa',
        /* internal name: '/Cadastro/Empresa' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/Cadastro/Empresa/',
            component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Cadastro/Empresa/index.vue'),
            /* no children */
          },
  _definePage_default_3
  ),
          {
            path: 'Edit',
            /* internal name: '/Cadastro/Empresa/Edit' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: '/Cadastro/Empresa/Edit/[id]',
                component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Cadastro/Empresa/Edit/[id].vue'),
                /* no children */
              },
  _definePage_default_4
  )
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/Erro',
    name: '/Erro',
    component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Erro.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/Login',
    name: '/Login',
    component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Login.vue'),
    /* no children */
    meta: {
      "layout": "clean"
    }
  },
  _definePage_default_5
  ),
  _mergeRouteRecord(
  {
    path: '/Logout',
    name: '/Logout',
    component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Logout.vue'),
    /* no children */
    meta: {
      "layout": "clean"
    }
  },
  _definePage_default_6
  ),
  _mergeRouteRecord(
  {
    path: '/Negado',
    name: '/Negado',
    component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Negado.vue'),
    /* no children */
  },
  _definePage_default_7
  ),
  {
    path: '/Sistema',
    /* internal name: '/Sistema' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'Arquivo',
        name: '/Sistema/Arquivo',
        component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Arquivo.vue'),
        /* no children */
      },
  _definePage_default_8
  ),
      {
        path: 'Grupo',
        /* internal name: '/Sistema/Grupo' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/Sistema/Grupo/',
            component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Grupo/index.vue'),
            /* no children */
          },
  _definePage_default_9
  ),
          {
            path: 'Edit',
            /* internal name: '/Sistema/Grupo/Edit' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: '/Sistema/Grupo/Edit/[id]',
                component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Grupo/Edit/[id].vue'),
                /* no children */
              },
  _definePage_default_10
  )
            ],
          }
        ],
      },
  _mergeRouteRecord(
      {
        path: 'Menu',
        name: '/Sistema/Menu',
        component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Menu.vue'),
        /* no children */
      },
  _definePage_default_11
  ),
  _mergeRouteRecord(
      {
        path: 'Parametro',
        name: '/Sistema/Parametro',
        component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Parametro.vue'),
        /* no children */
      },
  _definePage_default_12
  ),
      {
        path: 'Permissao',
        /* internal name: '/Sistema/Permissao' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/Sistema/Permissao/',
            component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Permissao/index.vue'),
            /* no children */
          },
  _definePage_default_13
  ),
          {
            path: 'Edit',
            /* internal name: '/Sistema/Permissao/Edit' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: '/Sistema/Permissao/Edit/[id]',
                component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Permissao/Edit/[id].vue'),
                /* no children */
              },
  _definePage_default_14
  )
            ],
          }
        ],
      },
      {
        path: 'Usuario',
        /* internal name: '/Sistema/Usuario' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/Sistema/Usuario/',
            component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Usuario/index.vue'),
            /* no children */
          },
  _definePage_default_15
  ),
          {
            path: 'Edit',
            /* internal name: '/Sistema/Usuario/Edit' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: '/Sistema/Usuario/Edit/[id]',
                component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/Sistema/Usuario/Edit/[id].vue'),
                /* no children */
              },
  _definePage_default_16
  )
            ],
          }
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/TrocarSenha',
    name: '/TrocarSenha',
    component: () => import('/home/runner/work/ModeloVue2025/ModeloVue2025/Web/VueApp/src/pages/TrocarSenha.vue'),
    /* no children */
  },
  _definePage_default_17
  )
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

