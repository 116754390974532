<template>
  <v-container>
    <v-form :disabled="Permissao.SomenteLeitura">
      <v-row>
        <v-col> <v-text-field :readonly="true" v-model="Model.Id" label="Id"></v-text-field> </v-col>
        <v-col> <v-switch v-model="Model.Ativo" label="Ativo"></v-switch></v-col>
      </v-row>

      <v-row>
        <v-col> <v-text-field v-model="Model.Nome" label="Nome"></v-text-field> </v-col>
        <v-col> <v-text-field v-model="Model.CpfCnpj" label="Cpf/Cnpj"></v-text-field> </v-col>
      </v-row>

      <v-row>
        <v-col> <DtPicker label="Data Contrato" v-model="Model.DataContrato"></DtPicker></v-col>
        <v-col> <VCurrencyField v-model="Model.ValorContrato" label="Valor Contrato"></VCurrencyField> </v-col>
      </v-row>
    </v-form>
    <SaveDelCancel :ReadOnly="Permissao.SomenteLeitura" :NoDelete="(Model.Id == 0)" v-on:save="Save()" v-on:cancel="Index()" v-on:delete="Delete()"></SaveDelCancel>
  </v-container>
</template>

<script setup>
definePage({
  meta: {
    title: "Cadastro de Empresas - Editar",
  },
});

import { inject } from "vue";
import { useAppStore } from "@/stores/app";

const router = useRouter();
const route  = useRoute();
const store  = useAppStore();
const api    = inject("SistemaApis");

const Permissao = computed(() => {
  return store.GetPermissao;
});

let Model = ref({
  DataContrato: new Date(),
});

async function Edit(id) {
  let response = await api.Empresa.Edit(id);
  Model.value = response.Dados;
}

async function Save() {
  try {
    await api.Empresa.Save(Model.value);
    Index();
  } catch {}
}

async function Delete() {
  try {
    let response = await api.Empresa.Delete(Model.value);
    Model.value = response.Dados;
    Index();
  } catch {}
}

function Index() {
  router.push("/Cadastro/Empresa");
}

onMounted(async () => {
  const id = route.params.id ?? null;

  if (id) {
    await Edit(id);
  } else {
    Index();
  }
});
</script>
